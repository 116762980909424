var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title", { attrs: { "show-back-btn": "" } }),
      _c("div", { staticClass: "partition-area" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading.detail,
                expression: "loading.detail"
              }
            ],
            staticClass: "partition-area"
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 10 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "appForm",
                        attrs: {
                          model: _vm.appForm,
                          rules: _vm.rules,
                          "label-width": "100px",
                          "label-suffix": _vm.constants.labelSuffix
                        }
                      },
                      [
                        _c(
                          "ics-page-inner",
                          { attrs: { title: "合同信息", "gutter-number": 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "模板文件" } },
                                  [
                                    _vm.viewEdit()
                                      ? _c(
                                          "p",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "primary" },
                                                on: { click: _vm.clickFiles }
                                              },
                                              [_vm._v(" 上传合同附件 ")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "p",
                                      [
                                        _vm.appForm.contractUrlName
                                          ? _c(
                                              "el-link",
                                              {
                                                staticClass: "text-btn",
                                                attrs: {
                                                  type: "primary",
                                                  underline: false
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.utils.downloadP(
                                                      "concat",
                                                      _vm.appForm.contractUrl
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .contractUrlName
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12, "label-position": "top" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "contractName",
                                      label: "模板名称"
                                    }
                                  },
                                  [
                                    _c(
                                      "ics-item-inner",
                                      {
                                        attrs: {
                                          prop: _vm.appForm.contractName,
                                          format: _vm.utils.isEffectiveCommon
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入模板名称"
                                          },
                                          model: {
                                            value: _vm.appForm.contractName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.appForm,
                                                "contractName",
                                                $$v
                                              )
                                            },
                                            expression: "appForm.contractName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.viewEdit()
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "capCode",
                                          label: "所属资金方"
                                        }
                                      },
                                      [
                                        _c(
                                          "ics-item-inner",
                                          {
                                            attrs: {
                                              prop: _vm.appForm.capCode,
                                              format:
                                                _vm.utils.isEffectiveCommon
                                            }
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder:
                                                    "请选择所属资金方",
                                                  filterable: ""
                                                },
                                                on: {
                                                  change: function(val) {
                                                    return _vm.companyChange(
                                                      val
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: _vm.appForm.capCode,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.appForm,
                                                      "capCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "appForm.capCode"
                                                }
                                              },
                                              _vm._l(_vm.capList, function(
                                                item
                                              ) {
                                                return _c("el-option", {
                                                  key: item.capCode,
                                                  attrs: {
                                                    label:
                                                      item.capName +
                                                      "（" +
                                                      item.capCode +
                                                      "）",
                                                    value: item.capCode
                                                  }
                                                })
                                              }),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.viewEdit()
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "所属资金方" } },
                                      [
                                        _c("ics-item-inner", {
                                          attrs: {
                                            prop: _vm.appForm.capName,
                                            format: _vm.utils.isEffectiveCommon
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c(
                                  "ics-page-inner",
                                  { attrs: { title: "签署方" } },
                                  [
                                    _vm.viewEdit() &&
                                    _vm.$route.params.editMode === "add"
                                      ? _c("template", { slot: "btn-inner" }, [
                                          _c(
                                            "div",
                                            { staticClass: "btn-inner" },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.addSignInfoButtonDisabled,
                                                    type: "primary"
                                                  },
                                                  on: {
                                                    click: _vm.addContractSign
                                                  }
                                                },
                                                [_vm._v(" 新增签署方 ")]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "signInfoList",
                                          label: "",
                                          "label-width": "40px"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-table",
                                          {
                                            staticClass: "table-input",
                                            staticStyle: {
                                              "margin-left": "-20px"
                                            },
                                            attrs: {
                                              data: _vm.appForm.signInfoList
                                            }
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "签署方",
                                                "show-overflow-tooltip": "",
                                                width: "80"
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _c("el-form-item", [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row
                                                                .signatory
                                                            )
                                                          )
                                                        ])
                                                      ])
                                                    ]
                                                  }
                                                }
                                              ])
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "签署方性质" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _vm.viewEdit() &&
                                                      _vm.$route.params
                                                        .editMode === "add"
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                "label-width":
                                                                  "0",
                                                                prop:
                                                                  "signInfoList[" +
                                                                  scope.$index +
                                                                  "].natureSignatory",
                                                                rules:
                                                                  _vm.rules
                                                                    .natureSignatory
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "error",
                                                                    fn: function(
                                                                      errScope
                                                                    ) {
                                                                      return [
                                                                        _c(
                                                                          "el-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              effect:
                                                                                "dark",
                                                                              content:
                                                                                errScope.error,
                                                                              placement:
                                                                                "top-start"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "error-icon danger"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "el-icon-warning"
                                                                                  }
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  attrs: {
                                                                    filterable:
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      value
                                                                    ) {
                                                                      return _vm.changeNatureSignatory(
                                                                        value,
                                                                        scope.$index
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      scope.row
                                                                        .natureSignatory,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "natureSignatory",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "scope.row.natureSignatory"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm._.filter(
                                                                    _vm
                                                                      .constants
                                                                      .companyType,
                                                                    {
                                                                      states: 1
                                                                    }
                                                                  ),
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key:
                                                                          item.value,
                                                                        attrs: {
                                                                          label:
                                                                            item.label,
                                                                          value:
                                                                            item.value
                                                                        }
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.$route.params
                                                        .editMode !== "add"
                                                        ? _c("el-form-item", [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.utils.statusFormat(
                                                                    String(
                                                                      scope.row
                                                                        .natureSignatory
                                                                    ),
                                                                    "companyType"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ])
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "签章字样" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _vm.viewEdit() &&
                                                      _vm.$route.params
                                                        .editMode === "add"
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                "label-width":
                                                                  "0",
                                                                prop:
                                                                  "signInfoList[" +
                                                                  scope.$index +
                                                                  "].signWordModel",
                                                                rules:
                                                                  _vm.rules
                                                                    .signWordModel
                                                              }
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "请输入签章字样"
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .signWordModel,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "signWordModel",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "scope.row.signWordModel"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.$route.params
                                                        .editMode !== "add"
                                                        ? _c("el-form-item", [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.utils.isEffectiveCommon(
                                                                    scope.row
                                                                      .signWordModel
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ])
                                            }),
                                            _vm.$route.params.editMode === "add"
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    label: "操作",
                                                    width: "100px"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(scope) {
                                                          return [
                                                            scope.$index > 0
                                                              ? _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "text-btn danger",
                                                                    attrs: {
                                                                      href:
                                                                        "javascript:"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.delSign(
                                                                          scope.$index
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "删除"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    2335978849
                                                  )
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "ics-page-inner",
                                  { attrs: { title: "变量" } },
                                  [
                                    _c(
                                      "el-form",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "选择变量：" }
                                              },
                                              [
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "250px"
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "请输入要搜索的变量编码"
                                                  },
                                                  on: {
                                                    change: _vm.changeCode
                                                  },
                                                  model: {
                                                    value: _vm.searchForm.code,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.searchForm,
                                                        "code",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "searchForm.code"
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: false,
                                                        expression: "false"
                                                      }
                                                    ]
                                                  },
                                                  [_c("p", [_c("el-input")], 1)]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "ics-page-inner",
                                  {
                                    staticStyle: { "margin-top": "-30px" },
                                    attrs: {
                                      title: "变量",
                                      "show-header": true
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "partition-area" },
                                      [
                                        _c(
                                          "el-table",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value: _vm.loading.list,
                                                expression: "loading.list"
                                              }
                                            ],
                                            staticClass: "mb20",
                                            attrs: {
                                              data: _vm.variableList,
                                              "max-height": "220px"
                                            }
                                          },
                                          [
                                            _vm.viewEdit()
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    label: "变量编码",
                                                    "min-width": "100",
                                                    "show-overflow-tooltip": ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(scope) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                attrs: {
                                                                  draggable:
                                                                    "true"
                                                                },
                                                                on: {
                                                                  dragstart: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.drag(
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href:
                                                                        "javascript:;"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .variable
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3885707716
                                                  )
                                                })
                                              : _vm._e(),
                                            !_vm.viewEdit()
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    prop: "variable",
                                                    label: "变量编码",
                                                    "min-width": "100",
                                                    "show-overflow-tooltip": ""
                                                  }
                                                })
                                              : _vm._e(),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "variableName",
                                                label: "名称",
                                                "min-width": "130",
                                                "show-overflow-tooltip": ""
                                              }
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "remark",
                                                label: "描述",
                                                "min-width": "130",
                                                "show-overflow-tooltip": ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "ics-page-inner",
                                  { attrs: { title: "模板内容" } },
                                  [
                                    _c("template", { slot: "btn-inner" }, [
                                      _c(
                                        "div",
                                        { staticClass: "btn-inner" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "success" },
                                              on: { click: _vm.viewerPdf }
                                            },
                                            [_vm._v(" 预览模板内容 ")]
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "editor-content" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "contractContent",
                                              label: ""
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "editor-container"
                                              },
                                              [
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.appForm
                                                          .contractContent,
                                                      expression:
                                                        "appForm.contractContent"
                                                    }
                                                  ],
                                                  attrs: { id: "editor" },
                                                  domProps: {
                                                    value:
                                                      _vm.appForm
                                                        .contractContent
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.appForm,
                                                        "contractContent",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("ics-upload-inner", {
        attrs: { "upload-dialog": _vm.dialog.upload },
        on: { onSuccessFile: _vm.onSuccessFile }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }